<button
  class="tw-inline tw-h-24 tw-w-24 tw-align-middle tw-text-black-light-7 hover:tw-text-black-light-5.5 active:tw-text-white"
  [ngClass]="iconColor() ? iconClasses() : ''"
  [appPopoverTrigger]="popover"
  [attr.data-cy]="'tooltipButton'"
  (click)="$event.stopPropagation()"
>
  <mat-icon class="tw-overflow-visible">{{ tooltipCustomIcon() }}</mat-icon>
</button>

<ng-template #popover>
  <div
    class="tw-me-6 tw-ms-6 tw-mt-6 tw-max-w-[500px] tw-border-2 tw-border-black-light-7 tw-bg-black-light-8 tw-text-black"
  >
    <div
      class="tw-flex tw-items-start tw-gap-6 tw-pb-9 tw-pe-9 tw-ps-15 tw-pt-9 tw-font-extra-bold d:tw-pb-12 d:tw-ps-18 d:tw-pt-12"
    >
      <div class="tw-size-24 tw-flex-grow-0">
        <mat-icon class="tw-size-24">{{ tooltipCustomIcon() }}</mat-icon>
      </div>

      <h4 class="msa-text-h4 tw-flex-grow tw-font-bold tw-text-black">{{ tooltipTitle() | translate }}</h4>
      <div class="tw-size-24 tw-flex-grow-0">
        <mat-icon class="tw-size-24 tw-cursor-pointer tw-text-black-light-4" appPopoverClose>close</mat-icon>
      </div>
    </div>

    @if (tooltipContent(); as tooltipContent) {
      <div
        class="tw-bg-inherit msa-text-small d:msa-text tw-border-t tw-border-t-black-light-7 tw-pb-15 tw-pe-15 tw-ps-15 tw-pt-9 tw-font-normal tw-not-italic d:tw-pe-18 d:tw-ps-18 d:tw-pt-12"
        [innerHtml]="tooltipContent | safeTranslate"
        (click)="$event.stopPropagation(); $event.preventDefault()"
      ></div>
    }
  </div>
</ng-template>
