import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {PopoverModule} from '@shared/shared-module/directives/popover.module';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';

/**
 * A component to show a tooltip to the user on click
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    TranslateModule,
    PopoverModule,
    SafeTranslatePipe
  ],
  selector: 'msa-tooltip',
  standalone: true,
  templateUrl: './msa-tooltip.component.html'
})
export class MsaTooltipComponent {
  public tooltipTitle = input<TranslationString>('');
  public tooltipCustomIcon = input('info_outline');
  public tooltipContent = input<TranslationString>();
  public iconColor = input<string>('');

  iconClasses = computed(() => {
    const hasCustomColor = this.iconColor() != '';
    return {
      [this.iconColor()]: hasCustomColor,
      ['hover:' + this.iconColor()]: hasCustomColor,
      ['active:' + this.iconColor()]: hasCustomColor
    };
  });
}
